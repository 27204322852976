<template>
  <div class="login-card">
    <div class="row">
      <div class="col-md-6 d-md-block d-none">
        <img class="w-100" src="../../assets/img/auth/login-backgroun.png" alt="">
        <div class="etmaam" :class="[this.$i18n.locale == 'en' ? 'en':'']">
          <img src="../../assets/img/global/Logo.webp" alt="Etmaam Logo">
          <p>
            {{
              this.$i18n.locale == 'ar' ?
                `استفد من خبرتنا واستمتع بخدمات متميزة تلبي احتياجاتك بدقة واحترافية.`
                :
                `Benefit from our experience and enjoy distinguished services that meet your needs accurately and
            professionally.`

            }}
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <Form class="auth-form" @submit="HandelReset" :validation-schema="schema">
          <div class="row">
            <div class="col-12">
              <h1 class="title">{{ $t('Reset') }}</h1>
            </div>
            <div class="col-12 field">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.375 7.71428C2.20924 7.71428 2.05027 7.78201 1.93306 7.90257C1.81585 8.02313 1.75 8.18664 1.75 8.35714V16.0714C1.75 16.2419 1.81585 16.4054 1.93306 16.526C2.05027 16.6466 2.20924 16.7143 2.375 16.7143H13.625C13.7908 16.7143 13.9497 16.6466 14.0669 16.526C14.1842 16.4054 14.25 16.2419 14.25 16.0714V8.35714C14.25 8.18664 14.1842 8.02313 14.0669 7.90257C13.9497 7.78201 13.7908 7.71428 13.625 7.71428H2.375ZM2.375 6.42857H13.625C14.1223 6.42857 14.5992 6.63176 14.9508 6.99344C15.3025 7.35511 15.5 7.84565 15.5 8.35714V16.0714C15.5 16.5829 15.3025 17.0735 14.9508 17.4351C14.5992 17.7968 14.1223 18 13.625 18H2.375C1.87772 18 1.40081 17.7968 1.04917 17.4351C0.697544 17.0735 0.5 16.5829 0.5 16.0714V8.35714C0.5 7.84565 0.697544 7.35511 1.04917 6.99344C1.40081 6.63176 1.87772 6.42857 2.375 6.42857Z"
                  fill="#0a3041" />
                <path
                  d="M8 9.64285C8.16576 9.64285 8.32473 9.71058 8.44194 9.83114C8.55915 9.9517 8.625 10.1152 8.625 10.2857V14.1429C8.625 14.3134 8.55915 14.4769 8.44194 14.5974C8.32473 14.718 8.16576 14.7857 8 14.7857C7.83424 14.7857 7.67527 14.718 7.55806 14.5974C7.44085 14.4769 7.375 14.3134 7.375 14.1429V10.2857C7.375 10.1152 7.44085 9.9517 7.55806 9.83114C7.67527 9.71058 7.83424 9.64285 8 9.64285ZM11.75 6.42857V5.14286C11.75 4.11988 11.3549 3.1388 10.6517 2.41544C9.94839 1.69209 8.99456 1.28571 8 1.28571C7.00544 1.28571 6.05161 1.69209 5.34835 2.41544C4.64509 3.1388 4.25 4.11988 4.25 5.14286V6.42857H11.75ZM8 0C9.32608 0 10.5979 0.541835 11.5355 1.50631C12.4732 2.47078 13 3.77889 13 5.14286V7.71428H3V5.14286C3 3.77889 3.52678 2.47078 4.46447 1.50631C5.40215 0.541835 6.67392 0 8 0Z"
                  fill="#0a3041" />
              </svg>
              <Field name="password" :type="type_password" id="password" :placeholder="$t('Password')" />
              <ErrorMessage name="password" class="error-message" />
              <svg v-if="type_password =='text'" @click="showPassword" class="svg-eye" width="16" height="11" viewBox="0 0 16 11" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 0.0458984C4.36364 0.0458984 1.25818 2.30772 0 5.50044C1.25818 8.69317 4.36364 10.955 8 10.955C11.6364 10.955 14.7418 8.69317 16 5.50044C14.7418 2.30772 11.6364 0.0458984 8 0.0458984ZM8 9.13681C5.99273 9.13681 4.36364 7.50772 4.36364 5.50044C4.36364 3.49317 5.99273 1.86408 8 1.86408C10.0073 1.86408 11.6364 3.49317 11.6364 5.50044C11.6364 7.50772 10.0073 9.13681 8 9.13681ZM8 3.31863C6.79273 3.31863 5.81818 4.29317 5.81818 5.50044C5.81818 6.70772 6.79273 7.68226 8 7.68226C9.20727 7.68226 10.1818 6.70772 10.1818 5.50044C10.1818 4.29317 9.20727 3.31863 8 3.31863Z"
                  fill="#225476" />
              </svg>
              <svg v-else @click="showPassword" class="svg-eye" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="18" height="13" >
                <path fill="#225476" 
                  d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
              </svg>
            </div>
            <div class="col-12 field">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.375 7.71428C2.20924 7.71428 2.05027 7.78201 1.93306 7.90257C1.81585 8.02313 1.75 8.18664 1.75 8.35714V16.0714C1.75 16.2419 1.81585 16.4054 1.93306 16.526C2.05027 16.6466 2.20924 16.7143 2.375 16.7143H13.625C13.7908 16.7143 13.9497 16.6466 14.0669 16.526C14.1842 16.4054 14.25 16.2419 14.25 16.0714V8.35714C14.25 8.18664 14.1842 8.02313 14.0669 7.90257C13.9497 7.78201 13.7908 7.71428 13.625 7.71428H2.375ZM2.375 6.42857H13.625C14.1223 6.42857 14.5992 6.63176 14.9508 6.99344C15.3025 7.35511 15.5 7.84565 15.5 8.35714V16.0714C15.5 16.5829 15.3025 17.0735 14.9508 17.4351C14.5992 17.7968 14.1223 18 13.625 18H2.375C1.87772 18 1.40081 17.7968 1.04917 17.4351C0.697544 17.0735 0.5 16.5829 0.5 16.0714V8.35714C0.5 7.84565 0.697544 7.35511 1.04917 6.99344C1.40081 6.63176 1.87772 6.42857 2.375 6.42857Z"
                  fill="#0a3041" />
                <path
                  d="M8 9.64285C8.16576 9.64285 8.32473 9.71058 8.44194 9.83114C8.55915 9.9517 8.625 10.1152 8.625 10.2857V14.1429C8.625 14.3134 8.55915 14.4769 8.44194 14.5974C8.32473 14.718 8.16576 14.7857 8 14.7857C7.83424 14.7857 7.67527 14.718 7.55806 14.5974C7.44085 14.4769 7.375 14.3134 7.375 14.1429V10.2857C7.375 10.1152 7.44085 9.9517 7.55806 9.83114C7.67527 9.71058 7.83424 9.64285 8 9.64285ZM11.75 6.42857V5.14286C11.75 4.11988 11.3549 3.1388 10.6517 2.41544C9.94839 1.69209 8.99456 1.28571 8 1.28571C7.00544 1.28571 6.05161 1.69209 5.34835 2.41544C4.64509 3.1388 4.25 4.11988 4.25 5.14286V6.42857H11.75ZM8 0C9.32608 0 10.5979 0.541835 11.5355 1.50631C12.4732 2.47078 13 3.77889 13 5.14286V7.71428H3V5.14286C3 3.77889 3.52678 2.47078 4.46447 1.50631C5.40215 0.541835 6.67392 0 8 0Z"
                  fill="#0a3041" />
              </svg>
              <Field name="ConfirmPassword" :type="type_ConfirmPassword" id="showConfirmPassword"
                :placeholder="$t('Confirm_Password')" />
              <ErrorMessage name="ConfirmPassword" class="error-message" />
              <svg  v-if="type_ConfirmPassword =='text'" @click="showConfirmPassword" class="svg-eye" width="16" height="11" viewBox="0 0 16 11" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 0.0458984C4.36364 0.0458984 1.25818 2.30772 0 5.50044C1.25818 8.69317 4.36364 10.955 8 10.955C11.6364 10.955 14.7418 8.69317 16 5.50044C14.7418 2.30772 11.6364 0.0458984 8 0.0458984ZM8 9.13681C5.99273 9.13681 4.36364 7.50772 4.36364 5.50044C4.36364 3.49317 5.99273 1.86408 8 1.86408C10.0073 1.86408 11.6364 3.49317 11.6364 5.50044C11.6364 7.50772 10.0073 9.13681 8 9.13681ZM8 3.31863C6.79273 3.31863 5.81818 4.29317 5.81818 5.50044C5.81818 6.70772 6.79273 7.68226 8 7.68226C9.20727 7.68226 10.1818 6.70772 10.1818 5.50044C10.1818 4.29317 9.20727 3.31863 8 3.31863Z"
                  fill="#225476" />
              </svg>
              <svg v-else @click="showConfirmPassword" class="svg-eye" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="18" height="13" >
                <path fill="#225476" 
                  d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
              </svg>
            </div>
            <div class="col-12 field" style="position: relative">

            </div>
            
            <div class="col-12">
              <button class="btn-main">{{ $t('Confirm') }}</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
// import vee-validate 
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
  data() {
    return {
      type_password: 'password',
      type_ConfirmPassword: 'password',
    }
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      password: yup.string()
          .required(cookie.get('lang') === 'ar' ? 'كلمة المرور  مطلوبة' : 'password is required')
          .min(8, cookie.get('lang') === 'ar' ? 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل' : 'password must be at least 8 characters'),

          ConfirmPassword: yup.string()
          .required(cookie.get('lang') === 'ar' ? 'كلمة المرور  مطلوبة' : 'password is required')
          .oneOf([yup.ref('password')], cookie.get('lang') === 'ar' ? 'يجب ان تكون كلمة المرور مطابقة' : 'password must be valid'),
    });

    return {
      schema,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    showPassword() {
      if (this.type_password == 'text') {
        this.type_password = 'password'
      }
      else {
        this.type_password = 'text';
      }
    },
    showConfirmPassword() {
      if (this.type_ConfirmPassword == 'text') {
        this.type_ConfirmPassword = 'password'
      }
      else {
        this.type_ConfirmPassword = 'text';
      }
    },
    async HandelReset(values) {
      const formData = {
        "password": values.password,
        "password_confirmation": values.ConfirmPassword,
      }
      await axios.post(`/user/auth/reset-password`, formData,{
        headers:{
            'Authorization': `Bearer ${window.localStorage.getItem('reset_token')}`
          }
      })
        .then((res) => {
          if(res.status == 200){
            this.$router.push('/login');
          notify({
            type: "success",
            title: "تغيير كلمة المرور",
            text: this.$i18n.locale == 'ar' ? 'تم تغيير كلمة المرور بنجاح':'password changed success',
          });
          }

        })
        .catch(function (error) {
          if(error){
            notify({
              type: "error",
              title: "خـطـأ !!",
              text: error?.response?.data?.message,
            });
          }
        });
    }
  }
}
</script>